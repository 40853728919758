













































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";
import { HowToPopup } from "@/components/common";
import { Interval, User, WordSegment } from "@/core/models";
import LocalStorage from "@/core/utils/LocalStorage";

@Component({ components: { HowToPopup } })
export default class WPMCard extends Vue {
  @Getter("theme") theme!: any;
  @Prop({ default: () => 0 }) wpm!: number;
  @Prop({ default: () => false }) zoom!: boolean;
  @Prop({ default: () => 0 }) length!: number;
  @Prop({ default: () => [] }) words!: WordSegment[];
  @Prop({ default: () => undefined }) interval!: Interval;
  @Prop({ default: () => undefined }) benchmarkUser!: User;

  VRMode = LocalStorage.getVRMode();

  get ititle() {
    return this.$t(
      "sessionsPage.sessionsListSection.speechSpeedCard.infoTitle",
    );
  }
  get itext() {
    return this.$t("sessionsPage.sessionsListSection.speechSpeedCard.infoText");
  }
  get headline() {
    return this.$t(
      "sessionsPage.sessionsListSection.speechSpeedCard.cardTitle",
    );
  }

  get wordsPerMinute() {
    if (!this.zoom) return this.wpm;
    const { start, end } = this.interval;
    const count = this.words.filter(x => x.offset >= start && x.offset <= end)
      .length;
    const time = (end - start) / (60 * 1000);
    return Math.round(count / time);
  }
}
