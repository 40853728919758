














































































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { Pause } from "@/core/models/sessions";
import { InformationTooltip } from "@/components/common";
import { getPercentageChartData } from "@/components/charts/util";
import DoughnutChartLabel from "@/components/charts/base-charts/DoughnutChartLabel.vue";
import LocalStorage from "@/core/utils/LocalStorage";

@Component({ components: { InformationTooltip, DoughnutChartLabel } })
export default class PausesCard extends Vue {
  @Getter("theme") theme!: any;
  @Prop({ default: () => 0 }) totalAudioTime!: number;
  @Prop({ default: () => 0 }) id!: number;
  @Prop({ default: () => 0 }) average!: number;
  @Prop({ default: () => 0 }) total!: number;
  @Prop({ default: () => 0 }) ppm!: number;
  @Prop({ default: () => [] }) pauses!: Pause[];

  VRMode = LocalStorage.getVRMode();

  get count() {
    return this.pauses.length;
  }
  get percentage() {
    const perc = this.ppm / 3;
    if (isNaN(perc)) return 0;
    return perc > 1 ? 100 : perc * 100;
  }
  get chartData() {
    const color1 = this.theme.primary + "";
    const color2 = this.theme.accent + "";
    return getPercentageChartData(this.percentage, [color1, color2]);
  }
}
